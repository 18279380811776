import React, { useEffect, useState, useCallback, useRef } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"
import Img from "gatsby-image"
import Video from "../components/Video"
import { useTransition, animated } from "react-spring"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowCircleDown } from "@fortawesome/free-solid-svg-icons"
import { Link as ScrollLink, Element } from "react-scroll"
import Fade from "react-reveal/Fade"
const DELAY_OFFSET = 50
const IndexPage = ({ data }) => {
  const ref = useRef([])
  const [flashContent, setFlash] = useState([])
  const [bottomPan, setBottomPan] = useState([])
  const flashTransitions = useTransition(flashContent, null, {
    from: {
      opacity: 0,
      transform: "scale(0)",
    },
    enter: { opacity: 1, transform: "scale(1)" },
    leave: { opacity: 0, transform: "scale(10)" },
    config: { mass: 5, tension: 500, friction: 200 },
  })

  const panUpTransitions = useTransition(bottomPan, null, {
    from: {
      opacity: 0,
      transform: "translate3d(0,-40px,0)",
    },
    enter: { opacity: 1, transform: "translate3d(0,0,0)" },
  })
  const reset = useCallback(() => {
    ref.current.map(clearTimeout)
    ref.current = []
    setFlash([])
    ref.current.push(setTimeout(() => setFlash(["Star of 20/20"]), 2000))
    ref.current.push(setTimeout(() => setFlash(["Winner of Superhuman"]), 4000))
    ref.current.push(
      setTimeout(
        () =>
          setFlash([
            <span>
              <span className="avoid-wrap">One of the</span>
              <span className="avoid-wrap" style={{ color: "#ecf0f1" }}>
                <b>fastest mathematical minds</b>
              </span>
              <span className="avoid-wrap">in the world</span>
            </span>,
          ]),
        6000
      )
    )
    ref.current.push(
      setTimeout(() => setBottomPan(["Scroll down for more info"]), 4000)
    )
  }, [])

  useEffect(() => void reset(), [reset])
  return (
    <Layout>
      <SEO title="Home" />
      <header className="masthead text-center text-white d-flex">
        <Img
          fluid={data.cover.childImageSharp.fluid}
          className="header-img"
          style={{ position: "absolute" }}
        />
        <div className="container my-auto">
          <div className="row" style={{ height: "30vh" }}>
            <div className="col-lg-12 mx-auto">
              {flashTransitions.map(({ item, props, key }) => {
                return (
                  <animated.h1
                    key={key}
                    style={props}
                    className="text-uppercase flash-text"
                  >
                    <strong className="js-nametag">{item}</strong>
                  </animated.h1>
                )
              })}
            </div>
          </div>
        </div>
        {panUpTransitions.map(({ item, props, key }) => {
          return (
            <animated.h4
              key={key}
              style={{
                ...props,
                position: "absolute",
                width: "100%",
                bottom: "0px",
              }}
            >
              <strong
                className="js-nametag text-uppercase"
                style={{ display: "block", lineHeight: 1.5 }}
              >
                {item}
              </strong>
              <ScrollLink
                offset={-50}
                className="hover-btn"
                to="about-mike"
                smooth={true}
                duration={2000}
              >
                <FontAwesomeIcon
                  className="fa-2x"
                  icon={faArrowCircleDown}
                ></FontAwesomeIcon>
              </ScrollLink>
            </animated.h4>
          )
        })}
      </header>
      <Element name="about-mike">
        <div className="container" style={{ paddingTop: "40px" }}>
          <div className="row">
            <div className="col-lg-8 mx-auto mb-4 order-2 text-center">
              <Video
                channel="vimeo"
                url="285945153"
                img={data.homepageVideo.childImageSharp.fluid}
              ></Video>
            </div>
            <div className="col-lg-4 mx-auto order-1 text-center">
              <p className="section-heading" style={{ fontWeight: 100 }}>
                Mike Byster solves complex math equations in his head—even
                faster than a calculator. For years, he's taught these skills to
                children around the country.
                <br />
                <br />
                Today, Mike seeks a corporate partner that shares his passion
                for childhood education. Together, he hopes they can take Mike’s
                skills and experience to every kid in every school in America. 
              </p>
              <h4>
                <Link to="/additional-videos">
                  See for yourself what kids can do after working with Mike.
                </Link>
              </h4>
            </div>
          </div>
        </div>
        <hr />
        <div className="container" style={{ maxWidth: "970px" }}>
          <div className="row">
            <div className="col-sm-12">
              <h1 className="text-center">Featured On</h1>
              <br />
            </div>
          </div>
          <div className="row mx-2">
            {[
              <a href="https://www.youtube.com/watch?v=hv5QLyBr1CQ">
                <Img fluid={data.twentyTwenty.childImageSharp.fluid} />
              </a>,
              <a href="https://www.youtube.com/watch?v=p_098lHOeDI">
                <Img fluid={data.superhuman.childImageSharp.fluid} />
              </a>,
              <a href="https://www.youtube.com/watch?v=Y7OQGyQUez0">
                <Img fluid={data.wcl.childImageSharp.fluid} />
              </a>,
              <a href="https://abcnews.go.com/2020/story?id=2690724&page=1">
                <Img fluid={data.abc.childImageSharp.fluid} />
              </a>,
              <a href="https://www.youtube.com/watch?v=Tg4EadS1zSs">
                <Img fluid={data.tedx.childImageSharp.fluid} />
              </a>,
              <Img fluid={data.npr.childImageSharp.fluid} />,
              <a href="https://www.chicagotribune.com/news/ct-xpm-2004-04-18-0404180466-story.html">
                <Img fluid={data.tribune.childImageSharp.fluid} />
              </a>,
              <a href="https://wgntv.com/2017/08/30/one-of-the-fastest-brains-in-the-world-mike-byster-blows-anchors-away-with-brainetics/">
                <Img fluid={data.wgn.childImageSharp.fluid} />
              </a>,
              <a href="https://www.youtube.com/watch?v=Sv9VLhODfWo">
                <Img fluid={data.hallmark.childImageSharp.fluid} />
              </a>,
              <a href="https://chicago.suntimes.com/2017/6/7/18363805/local-brainiac-mike-byster-competes-on-superhuman">
                <Img fluid={data.suntimes.childImageSharp.fluid} />
              </a>,
              <Img fluid={data.digest.childImageSharp.fluid} />,
              <Img fluid={data.gdny.childImageSharp.fluid} />,
            ].map((elem, idx) => (
              <Fade delay={DELAY_OFFSET * idx}>
                <div className="col-4 col-md-3 my-auto mx-auto">{elem}</div>
              </Fade>
            ))}
          </div>
        </div>
        <hr />
        <div class="container">
          <div className="row">
            <div className="col-md-4 my-3 mx-auto text-center">
              <Link
                className="btn btn-outline-primary btn-xl js-scroll-trigger"
                to="/corporate"
              >
                Partner With Mike
              </Link>
            </div>
            <div className="col-md-4 my-3 mx-auto text-center">
              <Link
                to="/about"
                className="btn btn-outline-primary btn-xl js-scroll-trigger"
              >
                Learn More About Mike
              </Link>
            </div>
            <div className="col-md-4 my-3 mx-auto text-center">
              <Link
                to="/contact"
                className="btn btn-outline-primary btn-xl js-scroll-trigger"
              >
                Come See Mike's Show
              </Link>
            </div>
          </div>
        </div>
      </Element>
    </Layout>
  )
}
export default IndexPage

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 1000) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export const pageQuery = graphql`
  query {
    homepageVideo: file(relativePath: { eq: "what-kids-can-do.png" }) {
      ...fluidImage
    }
    cover: file(relativePath: { eq: "tedx.png" }) {
      ...fluidImage
    }
    twentyTwenty: file(relativePath: { eq: "logos/2020.png" }) {
      ...fluidImage
    }
    wcl: file(relativePath: { eq: "logos/wcl.png" }) {
      ...fluidImage
    }
    hallmark: file(relativePath: { eq: "logos/hallmark.jpg" }) {
      ...fluidImage
    }
    superhuman: file(relativePath: { eq: "logos/superhuman.png" }) {
      ...fluidImage
    }
    tedx: file(relativePath: { eq: "logos/tedx.png" }) {
      ...fluidImage
    }
    tribune: file(relativePath: { eq: "logos/tribune.png" }) {
      ...fluidImage
    }
    wgn: file(relativePath: { eq: "logos/wgn.jpg" }) {
      ...fluidImage
    }
    abc: file(relativePath: { eq: "logos/abc.png" }) {
      ...fluidImage
    }
    npr: file(relativePath: { eq: "logos/npr.png" }) {
      ...fluidImage
    }
    suntimes: file(relativePath: { eq: "logos/suntimes.jpg" }) {
      ...fluidImage
    }
    digest: file(relativePath: { eq: "logos/digest.jpg" }) {
      ...fluidImage
    }
    gdny: file(relativePath: { eq: "logos/gdny.jpg" }) {
      ...fluidImage
    }
  }
`
